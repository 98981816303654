@keyframes opacityPulse {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.9;
  }
}

.opacity-pulse {
  animation: opacityPulse 1s infinite;
}
