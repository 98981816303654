@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;700&family=Nunito+Sans:wght@300;400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* base styles */
html {
  scroll-behavior: smooth;
}
body {
  font-family: "IBM Plex Sans", sans-serif;
  margin: 0;
  line-height: 1.1;
}
* {
  scrollbar-width: auto;
  scrollbar-color: #4adede #0f172a;
}
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #0f172a;
}
*::-webkit-scrollbar-thumb {
  background-color: #4adede;
  border-radius: 10px;
  border: 1px solid #0f172a;
}

input {
  display: block;
}
input:focus {
  outline: none;
}

ul,
li,
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}
input:disabled,
button:disabled,
span:disabled,
span:-moz-user-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
ul {
  list-style-type: none;
}
a.register {
  padding: 0;
}
a:visited {
  color: currentColor;
}
