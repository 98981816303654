.button-gradient {
  background: linear-gradient(-45deg, #ffee00, gold, rgb(227, 186, 0));
  transition-property: background-color, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  overflow: hidden;
  text-decoration: none;
  position: relative;
  z-index: 1;
}
.button-gradient:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(45deg, gold, #ffe494, rgb(214, 182, 0));
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: -1;
}

.button-gradient:hover:after {
  opacity: 1;
}
